import { t } from '@lingui/core/macro';
/* eslint-disable @nx/enforce-module-boundaries */
import { put, select, delay, takeEvery } from 'typed-redux-saga';
import {
  addFilesProcessing,
  addToPinnedUploads,
  handleUploadErrorToast,
  uppyUploadError,
  uppyUploadSuccess,
} from '../../actions';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
  contentLibraryUploadAssetsSelectors,
  searchAssetsSelectors,
  sectionEntitySelectors,
  uploadAssetsSelectors,
} from '@integration-frontends/integration/core/application/selectors';
import {
  ASSET_REPO_TOKEN,
  Asset,
  AssetType,
  IAssetRepo,
  ResourceBaseSortableProperty,
  SearchParameters,
  Section,
  SortDirection,
  SortOptions,
  hasFilters,
  hasQueryFilter,
} from '@integration-frontends/integration/core/model';
import { DI_CONTAINER, STORE_TOKEN } from '@integration-frontends/core';
import { Dictionary } from 'lodash';
import {
  AttachmentInputDto,
  ResourceType,
  ResourceDtoBase,
} from '@integration-frontends/common/brandfolder-api';
import { handleProcessFile } from './common';
import { assetEntityActions } from '@integration-frontends/integration/core/application/common';
import {
  Banner,
  BannerType,
  createBanner,
  selectOpenBanners,
  updateBanner,
} from '@integration-frontends/common/notifications';
import { baseSearch } from '@integration-frontends/integration/core/application/search-assets';
import { Store } from '@reduxjs/toolkit';
import { UppyFile } from '@brandfolder/uploader';
import { removeFileExtension } from '@brandfolder/utilities';
import { AttachmentSources } from './sources';
import { diContainer } from '@smartsheet/cufflink';
import { LoggingStateService, loggingStateServiceKey } from '@smartsheet/logging-state-service';

export function* uppyUploadSuccessEffects() {
  yield takeEvery(uppyUploadSuccess, handler);
}

const handler = function* (action: ReturnType<typeof uppyUploadSuccess>) {
  const { file } = action.payload;
  const assetRepo: IAssetRepo = DI_CONTAINER.get(ASSET_REPO_TOKEN);
  const sections = yield select(sectionEntitySelectors.selectEntities);
  const collectionId = yield select(uploadAssetsSelectors.selectedCollectionId);
  const loggingStateService = diContainer.get<LoggingStateService>(loggingStateServiceKey.name);
  let filesAddedSuccess = yield select(contentLibraryUploadAssetsSelectors.uppyReadyForUpload);

  while (filesAddedSuccess === null) {
    filesAddedSuccess = yield select(contentLibraryUploadAssetsSelectors.uppyReadyForUpload);
    yield delay(1000);
  }

  if (!filesAddedSuccess) {
    return;
  }

  const sectionId = getSectionId(file, sections);
  const attachment = formattedAttachment(file);
  const fileName = removeFileExtension(file.name);

  let assetResponse: ResourceDtoBase;
  try {
    const response = yield assetRepo.uppyUpload(sectionId, fileName, attachment, collectionId);
    assetResponse = response?.data;
  } catch (error) {
    if (error[0]?.message === 'upload denied due to 5000 asset limit') {
      yield put(uppyUploadError({ uploadErrorType: 'uploadExceedsFileLimitError' }));
    } else {
      // IMPORTANT: do not log customer/user data, fileName is intentionally omitted
      loggingStateService.error(
        'Error uploading asset in Content Library',
        error,
        new Map([
          ['collectionId', collectionId],
          ['fileExtension', file.extension],
          ['fileId', file.id],
          ['fileSize', file.size.toString()],
          ['fileType', file.type],
          ['sectionId', sectionId],
          ['tusUploadUrl', (file as any).tus?.uploadUrl || ''],
        ]),
        'content-library',
        undefined,
        'content-library',
      );
      yield put(handleUploadErrorToast());
    }
    return;
  }

  if (assetResponse) {
    try {
      const asset = formattedAsset(assetResponse, file, fileName);
      yield put(assetEntityActions.assetsReceived([asset]));
      yield addPinnedUploads(asset);
      yield put(addFilesProcessing({ assetIds: [asset?.id] }));
      yield handleProcessFile(asset?.id);
    } catch (error) {
      // IMPORTANT: do not log customer/user data, fileName is intentionally omitted
      loggingStateService.error(
        'Error processing uploaded asset in Content Library',
        error,
        new Map([
          ['assetId', assetResponse.id],
          ['collectionId', collectionId],
          ['fileExtension', file.extension],
          ['fileId', file.id],
          ['fileSize', file.size.toString()],
          ['fileType', file.type],
          ['sectionId', sectionId],
          ['tusUploadUrl', (file as any).tus?.uploadUrl || ''],
        ]),
        'content-library',
        undefined,
        'content-library',
      );
    }
  }
};

export const getSectionId = (file: UppyFile, sections: Dictionary<Section>) => {
  const sectionsData: Section[] = Object.values(sections);

  const mimeType =
    file.type.split('/')[0] === 'image' || file.type.split('/')[0] === 'video'
      ? 'image'
      : 'document';
  const sectionName = mimeType === 'image' ? 'Images' : 'Documents';
  const section = sectionsData.find((item) => item.name === sectionName);
  return section?.id;
};

export const formattedAsset = (asset: ResourceDtoBase, file: UppyFile, fileName: string): Asset => {
  return {
    availability: 'published',
    attachmentCount: 1,
    extension: file.extension,
    id: asset.id,
    name: fileName,
    position: undefined,
    sectionId: undefined,
    thumbnailUrl: null,
    type: asset.type as unknown as AssetType,
  };
};

export const formattedAttachment = (file: UppyFile): AttachmentInputDto => {
  return {
    filename: file.name,
    mimetype: file.type,
    source: AttachmentSources.SmartsheetContentLibraryUppy,
    type: ResourceType.ATTACHMENT,
    url: file.meta.brandfolderTusUrl,
  };
};

function* addPinnedUploads(asset: Asset) {
  const searchParams: SearchParameters = yield select(searchAssetsSelectors.searchParams);
  const sortOptions: SortOptions = yield select(searchAssetsSelectors.sortOptions);
  const filterApplied = hasFilters(searchParams);
  const searchApplied = hasQueryFilter(searchParams);
  const sortApplied = !(
    sortOptions.field === ResourceBaseSortableProperty.CreatedAt &&
    sortOptions.direction === SortDirection.Desc
  );

  // we only want to pin assets if there are no search or sort filters applied
  if (filterApplied || sortApplied) {
    yield createClearFilterBanner(filterApplied, searchApplied, sortApplied);
  } else {
    yield put(addToPinnedUploads({ asset }));
  }
}

function* createClearFilterBanner(
  filterApplied: boolean,
  searchApplied: boolean,
  sortApplied: boolean,
) {
  const store: Store = DI_CONTAINER.get(STORE_TOKEN);

  const activeText = () => {
    if (searchApplied) {
      return t`Search active.`;
    }
    if (filterApplied) {
      return t`Filters active.`;
    }
    if (sortApplied) {
      return t`Sort active.`;
    }
  };

  const bannerId = 'clear-filters-banner-' + crypto.randomUUID();
  const openBanners = yield select(selectOpenBanners);
  const existingClearFiltersBanner: Banner = openBanners.find((banner) =>
    banner.id.startsWith('clear-filters-banner-'),
  );
  // only create a banner if there isn't already one with clear-filters-banner- prefix
  if (existingClearFiltersBanner) {
    if (existingClearFiltersBanner.bannerContent.contentText !== activeText()) {
      yield put(
        updateBanner({
          id: existingClearFiltersBanner.id,
          bannerContent: { contentText: activeText() },
        }),
      );
    }
    return;
  }

  yield put(
    createBanner({
      id: bannerId,
      type: BannerType.Info,
      bannerContent: {
        contentText: activeText(),
        actionText: t`Clear to view all files`,
        displayDismissButton: true,
        actionTextAction: () => {
          store.dispatch(
            baseSearch({
              searchParams: null,
              sortOptions: {
                direction: SortDirection.Desc,
                field: ResourceBaseSortableProperty.CreatedAt,
              },
            }),
          );
        },
      },
    }),
  );
}
